import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Space, Popconfirm, Button, Tag, message } from "antd";

import { BrandingContext } from "../BrandingContext";

import REQUESTS from "../../../api/requests";

import { ICONS } from "../../../config";

import useTranslate from "../../../hooks/translator";
import { setAppBranding } from "../../../store/features/profileSlice";

import { ButtonComponent } from "../../../components";

export default function Header() {
  const {
    backgroundImage,
    logo,
    logoSize,
    menuIconsCollection,
    menuIconsColor,
    menuItemsActiveColor,
    menuItemsBoxForm,
    menuItemsColor,
    menuOption,
    playerIconsCollection,
    playerItemsColor,
    playerVersion,
    // settingsVersion,
    primaryColor,
    progressBarColor,
    secondaryColor,
    setLogo,
    setLogoSize,
    setMenuIconsCollection,
    setMenuIconsColor,
    setMenuItemsActiveColor,
    setMenuItemsBoxForm,
    setMenuItemsColor,
    setMenuOption,
    setPlayerIconsCollection,
    setPlayerItemsColor,
    setPlayerVersion,
    // setSettingsVersion,
    setPrimaryColor,
    setProgressBarColor,
    setSecondaryColor,
    setBackgroundImage,
  } = useContext(BrandingContext);

  const dispatch = useDispatch();
  const translate = useTranslate();

  const { profile, appBranding } = useSelector((store) => store.profile);

  const [loading, setLoading] = useState(false);

  const getAppBranding = () => {
    REQUESTS.APP_BRANDINGS.GET()
      .then((data) => {
        if (data) {
          dispatch(setAppBranding(data));
        }
      })
      .catch(() => {});
  };

  const onSubmit = () => {
    const formData = new FormData();
    setLoading(true);

    if (backgroundImage?.file) {
      formData.append("background_image", backgroundImage?.file);
    }
    if (logo?.file) {
      formData.append("logo", logo?.file);
    }
    if (logoSize && logoSize !== appBranding?.logo_size) {
      formData.append("logo_size", logoSize);
    }
    if (
      menuIconsCollection &&
      menuIconsCollection !== appBranding?.menu_icons_collection
    ) {
      formData.append("menu_icons_collection", menuIconsCollection);
    }
    if (menuIconsColor && menuIconsColor !== appBranding?.menu_icons_color) {
      formData.append("menu_icons_color", menuIconsColor);
    }
    if (
      menuItemsActiveColor &&
      menuItemsActiveColor !== appBranding?.menu_items_active_color
    ) {
      formData.append("menu_items_active_color", menuItemsActiveColor);
    }
    if (
      menuItemsBoxForm &&
      menuItemsBoxForm !== appBranding?.menu_items_box_form
    ) {
      formData.append("menu_items_box_form", menuItemsBoxForm);
    }
    if (menuItemsColor && menuItemsColor !== appBranding?.menu_items_color) {
      formData.append("menu_items_color", menuItemsColor);
    }
    if (menuOption && menuOption !== appBranding?.menu_option) {
      formData.append("menu_option", menuOption);
    }
    if (
      playerIconsCollection &&
      playerIconsCollection !== appBranding?.player_icons_collection
    ) {
      formData.append("player_icons_collection", playerIconsCollection);
    }
    if (
      playerItemsColor &&
      playerItemsColor !== appBranding?.player_items_color
    ) {
      formData.append("player_items_color", playerItemsColor);
    }
    if (playerVersion && playerVersion !== appBranding?.player_version) {
      formData.append("player_version", playerVersion);
    }
    // if (settingsVersion && settingsVersion !== appBranding?.settings_version) {
    //   formData.append("settings_version", settingsVersion);
    // }
    if (primaryColor && primaryColor !== appBranding?.primary_color) {
      formData.append("primary_color", primaryColor);
    }
    if (
      progressBarColor &&
      progressBarColor !== appBranding?.progress_bar_color
    ) {
      formData.append("progress_bar_color", progressBarColor);
    }
    if (secondaryColor !== appBranding?.secondary_color) {
      formData.append("secondary_color", secondaryColor);
    }

    REQUESTS.APP_BRANDINGS.PUT(formData)
      .then((res) => {
        setLoading(false);
        message.success(res);
        getAppBranding();
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const onReset = () => {
    REQUESTS.BRAND_RESET()
      .then((res) => {
        message.success(res);
        getAppBranding();
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onBack = () => {
    setBackgroundImage(appBranding.background_image);
    setLogo(appBranding.logo);
    setLogoSize(appBranding.logo_size);
    setMenuIconsCollection(appBranding.menu_icons_collection);
    setMenuIconsColor(appBranding.menu_icons_color || "");
    setMenuItemsActiveColor(appBranding.menu_items_active_color || "");
    setMenuItemsBoxForm(appBranding.menu_items_box_form);
    setMenuItemsColor(appBranding.menu_items_color || "");
    setMenuOption(appBranding.menu_option);
    setPlayerIconsCollection(appBranding.player_icons_collection || 1);
    setPlayerItemsColor(appBranding.player_items_color || "");
    setPlayerVersion(appBranding.player_version || 1);
    // setSettingsVersion(appBranding.settings_version || 3);
    setPrimaryColor(
      appBranding.primary_color?.startsWith("linear-gradient")
        ? ""
        : appBranding.primary_color
    );
    setProgressBarColor(appBranding.progress_bar_color || "");
    setSecondaryColor(appBranding.secondary_color || "");
  };

  return (
    <div className="head-page">
      <h3>{translate("Branding")}</h3>

      {profile?.branding ? (
        <Space direction="horizontal">
          <div className="btn-back" onClick={onBack}>
            {ICONS.BACK}
          </div>

          <Popconfirm
            title={translate("Are you sure to reset this branding")}
            onConfirm={onReset}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <Button
              disabled={!profile?.branding ? true : false}
              type="default"
              className="btn-dashed"
              danger
            >
              {translate("Reset")}
            </Button>
          </Popconfirm>

          <ButtonComponent
            type="primary"
            onClick={onSubmit}
            loading={loading}
            disabled={!profile?.branding ? true : false}
          >
            {translate("Save")}
          </ButtonComponent>
        </Space>
      ) : (
        <Tag color="gold">{translate("You cannot use branding")}</Tag>
      )}
    </div>
  );
}
