import React, { useState } from "react";
import { Tabs } from "antd";

import { BrandingProvider } from "./BrandingContext";

import useTranslate from "../../hooks/translator";

import { PageComponent } from "../../components";

import Header from "./components/Header";

import GeneralInfo from "./pages/GeneralInfo";
import MenuApp from "./pages/MenuApp";
import LiveTv from "./pages/LiveTv";
import Media from "./pages/Media";
import PlayerApp from "./pages/PlayerApp";
// import Settings from "./pages/Settings";

import "./styles/branding.scss";

export default function BrandingPage() {
  const translate = useTranslate();

  const [activeTab, setActiveTab] = useState("General Info");

  const items = [
    {
      key: "General Info",
      label: translate("General Info"),
      children: <GeneralInfo />,
    },
    {
      key: "Menu",
      label: translate("Menu"),
      children: <MenuApp />,
    },
    {
      key: "Player",
      label: translate("Player"),
      children: <PlayerApp />,
    },
    // {
    //   key: "Settings",
    //   label: translate("Settings"),
    //   children: <Settings />,
    // },
    {
      key: "Live TV",
      label: translate("Live TV"),
      children: <LiveTv />,
    },
    {
      key: "Media",
      label: translate("Media"),
      children: <Media />,
    },
  ];

  return (
    <BrandingProvider>
      <PageComponent>
        <Header />
        <Tabs
          activeKey={activeTab}
          items={items}
          onChange={(key) => setActiveTab(key)}
        />
      </PageComponent>
    </BrandingProvider>
  );
}
