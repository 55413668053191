import React, { useState } from "react";
import { Tabs } from "antd";
import useTranslate from "../../../hooks/translator";

import Deactivated from "./Deactivated";
import DeactivatedHistory from "./DeactivatedHistory";

export default function DeactivatedTab({
  getDeviceInfo,
  getProfile,
  platforms,
  selected,
  setSelected,
  isDrawerOpen,
  setIsDrawerOpen,
  myActivate,
  setMyActivate,
  activeTab,
}) {
  const translate = useTranslate();
  const [tab, setTab] = useState("Deactivated");

  const onChange = (key) => {
    setTab(key);
  };
  const items = [
    {
      key: "Deactivated",
      label: translate("Deactivated"),
      children: (
        <Deactivated
          activeTab={tab}
          tab={tab}
          getDeviceInfo={getDeviceInfo}
          getProfile={getProfile}
          platforms={platforms}
          selected={selected}
          setSelected={setSelected}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          myActivate={myActivate}
          setMyActivate={setMyActivate}
        />
      ),
    },

    {
      key: "Deactivated history",
      label: translate("Deactivated history"),
      children: <DeactivatedHistory activeTab={tab} />,
    },
  ];
  return (
    <Tabs
      defaultActiveKey={tab}
      activeKey={tab}
      items={items}
      type="card"
      onChange={onChange}
    />
  );
}
