import { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { Drawer, Form, Input, Checkbox, Select, message } from "antd";

import REQUESTS from "../../api/requests";

import { useHostsOptions } from "../../hooks/selectOptions";

import useTranslate from "../../hooks/translator";

import { setProfile } from "../../store/features/profileSlice";

import { freeTrialOptions } from "../../config/data";

import { ErrorMassage, ButtonComponent, InfoPopover } from "../../components";

import { deviceInfoModal } from "./infoModal";

export default function DevicesDrawer({
  open,
  setOpen,
  getData,
  getDeviceInfo,
  selected,
  myActivate,
}) {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const { profile } = useSelector((state) => state.profile);

  const [form] = Form.useForm();

  const hostListOptions = useHostsOptions();

  const hostListNewOptions = [...hostListOptions];

  const needBuy =
    profile?.parent_id && profile?.status === "NEED_BUY_PARTNER_CREDITS";

  const canUseFree =
    profile?.parent_id && profile?.status === "CAN_USE_FREE_PARTNER_CREDITS";

  if (open && profile) {
    if (!canUseFree) {
      hostListNewOptions.unshift({
        value: null,
        label: "Custom Playlist",
      });
    }
  }

  const [loading, setLoading] = useState(false);

  const [disabled, setDisabled] = useState(false);

  const [errorMess, setErrorMess] = useState(null);

  const deviceFreeTrialOptions = [
    {
      value: 0,
      label: "Activate without free trail",
    },
    ...freeTrialOptions,
  ];

  useEffect(() => {
    setErrorMess(null);
    setLoading(false);

    if (open) {
      if (selected) {
        convertUsernamePasswordOnPlaylist();
        form.setFields([
          { name: "device_key", value: selected.device_key },
          { name: "playlist", value: selected.playlist },
          { name: "auto_renew", value: selected.auto_renew },
          { name: "trial_period", value: selected.trial_period },
        ]);
      }
    } else {
      form.resetFields();
      setErrorMess(null);
      setLoading(false);
      setDisabled(false);
    }
  }, [open, selected]);

  const getProfile = () => {
    REQUESTS.PROFILE()
      .then((data) => {
        dispatch(setProfile(data));
      })
      .catch((err) => {});
  };

  const getValidMac = async (val) => {
    if (!val || val.length < 6) {
      return Promise.resolve();
    }

    if (val.length === 6) {
      try {
        await REQUESTS.VALIDATE_MAC({ device_key: val });
        setDisabled(false);
      } catch (err) {
        const errorTranslations = {
          "Device not found": translate("Device not found"),
          "The devices are busy with another provider": translate(
            "The devices are busy with another provider"
          ),
          "This device is attached to you, you can find it in your device list":
            translate(
              "This device is attached to you, you can find it in your device list"
            ),
        };

        const translatedError =
          errorTranslations[err] || translate("An unknown error occurred");

        form.setFields([{ name: "device_key", errors: [translatedError] }]);
        setDisabled(true);
        return Promise.reject(translatedError);
      }
    }

    return Promise.resolve();
  };

  const onFieldsChange = (changedField, allFields) => {
    setErrorMess(null);
    setLoading(false);

    switch (changedField[0].name[0]) {
      case "server_id":
        form.setFields([
          { name: "server_id", errors: [] },
          { name: "playlist", value: null },
        ]);
        break;
      case "trial_period":
        form.setFieldsValue({ auto_renew: false });
        break;

      default:
        break;
    }
  };

  const convertUsernamePasswordOnPlaylist = () => {
    let currentPlaylist = selected?.playlist?.split("=");
    if (!currentPlaylist) return;
    let username = currentPlaylist[1]?.substring(
      0,
      currentPlaylist[1]?.length - 9
    );
    let password = currentPlaylist[2];

    form.setFields([
      { name: "server_id", value: selected.server_id },
      { name: "username", value: username },
      { name: "password", value: password },
    ]);
  };

  function onFinish(values) {
    if (!open) return;
    setLoading(true);

    setErrorMess(null);

    const body = {
      auto_renew: values.auto_renew || false,
    };

    // if provider id not partner or subreseller we must be send playlist to the server when dns is not selected

    if (values.playlist) {
      body.playlist = values.playlist;
    }

    if (!selected) {
      body.device_key = values.device_key;
    }

    if (values.server_id) {
      body.server_id = Number(values.server_id);
    }

    if (values.username) {
      body.username = values.username.trim();
    }

    if (values.password) {
      body.password = values.password.trim();
    }

    function onSuccess() {
      setOpen(false);
      getData();
      getDeviceInfo();
    }

    function onError(err) {
      setLoading(false);
      setErrorMess(err);
    }

    if (selected) {
      body.id = selected.id;

      if (!values.server_id) {
        body.server_id = null;
      }

      REQUESTS.DEVICE_UPDATE(body)
        .then((res) => {
          !myActivate && message.success(res);
          if (myActivate && selected) {
            const activateBody = {
              id: selected.id,
            };
            REQUESTS.ACTIVATE_DEVICE(activateBody)
              .then((res) => {
                if (res.error) {
                  message.error(res.message);
                  return;
                }
                onSuccess();
                getProfile();
                message.success("Success");
              })
              .catch((err) => {
                onError(err);
                return;
              });
          } else {
            onSuccess();
          }
        })
        .catch((err) => {
          onError(err);
          return;
        });
    } else {
      // if provider id not partner or subreseller or (partner && and not have sync_playlist) we must be send trial period to the server when we want to activate device for free trial
      if (
        (!profile?.is_partner &&
          !profile?.parent_id &&
          values?.trial_period !== 0) ||
        needBuy ||
        (profile?.is_partner && !profile?.sync_playlist)
      ) {
        if (values.trial_period) {
          body.trial_period = values.trial_period;
        }
      }

      REQUESTS.ADD_ACTIVATION_DEVICE(body)
        .then((res) => {
          onSuccess();
          getProfile();
          message.success(res);
        })
        .catch((err) => {
          onError(err);
        });
    }
  }

  return (
    <Drawer
      forceRender
      open={open}
      onClose={setOpen}
      placement="right"
      title={
        <>
          {myActivate && selected
            ? translate("Activate device")
            : selected && !myActivate
            ? translate("Edit playlist")
            : translate("Add new device")}

          <InfoPopover
            content={deviceInfoModal(translate)}
            title={translate("New device configuration guide")}
            className="info-guide"
          />
        </>
      }
    >
      <Form
        layout="vertical"
        onFieldsChange={onFieldsChange}
        form={form}
        onFinish={onFinish}
        initialValues={{
          trial_period: 0,
          server_id: null,
          auto_renew: false,
        }}
      >
        <Form.Item
          label={translate("Device key")}
          name="device_key"
          messageVariables={{ name: "Device key" }}
          validateFirst
          rules={[
            {
              required: true,
              message: translate("Please enter device key"),
            },
            {
              min: 6,
              message: translate("Device key must be 6 characters"),
            },
            {
              validator: (_, val) =>
                selected ? Promise.resolve() : getValidMac(val),
            },
          ]}
        >
          <Input
            maxLength={6}
            placeholder={translate("Enter device key")}
            disabled={selected?.device_key ? true : false}
          />
        </Form.Item>

        {!selected &&
          ((!profile?.is_partner && !profile?.parent_id) ||
            needBuy ||
            (profile?.is_partner && !profile?.sync_playlist)) && (
            <Form.Item
              label={translate("Activate now or add free trial to device")}
              name="trial_period"
            >
              <Select options={deviceFreeTrialOptions} listHeight={400} />
            </Form.Item>
          )}

        <Form.Item shouldUpdate noStyle>
          {() => {
            const { server_id } = form.getFieldsValue();
            return (
              <>
                <Form.Item
                  label={translate("Select DNS")}
                  name="server_id"
                  rules={[
                    {
                      required:
                        hostListNewOptions[0]?.label == "Custom Playlist"
                          ? false
                          : true,
                      message: translate("Select DNS"),
                    },
                  ]}
                >
                  <Select
                    options={hostListNewOptions}
                    placeholder={translate("Select DNS")}
                  />
                </Form.Item>

                {!server_id && !canUseFree ? (
                  <Form.Item
                    label={translate("Playlist URL")}
                    name="playlist"
                    rules={[
                      {
                        required: true,
                        message: translate("Please input playlist URL"),
                      },
                      {
                        type: "url",
                        message: translate("That's not a valid URL"),
                      },
                    ]}
                  >
                    <Input placeholder={translate("Playlist URL")} type="url" />
                  </Form.Item>
                ) : (
                  <div>
                    <Form.Item
                      label={translate("Username")}
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: translate("Please input your username"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label={translate("Password")}
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: translate("Please input your password"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                )}
              </>
            );
          }}
        </Form.Item>

        {((!profile?.is_partner && !profile?.parent_id) || needBuy) && (
          <Form.Item shouldUpdate noStyle>
            {() => {
              const { trial_period } = form.getFieldsValue();

              return (
                <div className="checkbox-row">
                  <Form.Item
                    label=""
                    name="auto_renew"
                    valuePropName="checked"
                    rules={[
                      {
                        required: false,
                        message: translate("Please check auto renew"),
                      },
                    ]}
                  >
                    <Checkbox disabled={trial_period || selected?.trial_period}>
                      {" "}
                      {translate("Auto renew when device expired")}{" "}
                    </Checkbox>
                  </Form.Item>
                </div>
              );
            }}
          </Form.Item>
        )}

        <Form.Item>
          <ButtonComponent
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={disabled}
            style={{ marginTop: 20, width: "100%" }}
          >
            {myActivate && selected ? translate("Activate") : translate("Save")}
          </ButtonComponent>
        </Form.Item>
      </Form>

      {errorMess && <ErrorMassage err={errorMess} />}
    </Drawer>
  );
}
