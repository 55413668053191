import { useContext } from "react";

import { BrandingContext } from "../BrandingContext";

import { menuBrandingIconsCollection } from "../../../config/data";
import { returnColor } from "../../../config/utils";
import IMAGES from "../../../config/images";

export default function MenuRmsButtons() {
  const {
    menuIconsCollection,
    menuIconsColor,
    menuItemsActiveColor,
    menuItemsColor,
    menuItemsBoxForm,
  } = useContext(BrandingContext);

  const svgBackground = `<svg xmlns="http://www.w3.org/2000/svg" width="296" height="297" viewBox="0 0 296 297" fill="none">
<g filter="url(#filter0_f_4913_8293)">
<circle cx="147.906" cy="148.671" r="84.9062" transform="rotate(-90 147.906 148.671)" fill="url(#paint0_linear_4913_8293)"/>
</g>
<defs>
<filter id="filter0_f_4913_8293" x="0.106495" y="0.871143" width="295.6" height="295.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="31.4468" result="effect1_foregroundBlur_4913_8293"/>
</filter>
<linearGradient id="paint0_linear_4913_8293" x1="147.906" y1="63.7647" x2="147.906" y2="233.577" gradientUnits="userSpaceOnUse">
<stop stop-color="${returnColor(menuItemsActiveColor, 10)}"/>
<stop offset="1" stop-color="${returnColor(menuItemsColor, 10)}"/>
</linearGradient>
</defs>
</svg>`;

  const returnMenuItemsBoxForm = () => {
    switch (menuItemsBoxForm) {
      case "round":
        return "50%";
      case "quadrate":
        return "0px";
      case "halfcircle":
        return "15px";
      default:
        return "50%";
    }
  };

  const returnMenuItemsBoxBG = () => {
    switch (menuItemsBoxForm) {
      case "round":
        return IMAGES.ROUND;
      case "quadrate":
        return IMAGES.QUADRATE;
      case "halfcircle":
        return IMAGES.HALFCIRCLE;
      default:
        return IMAGES.ROUND;
    }
  };

  return (
    <div className="rms-menu-items">
      {menuBrandingIconsCollection[menuIconsCollection - 1] ? (
        Object?.entries(
          menuBrandingIconsCollection[menuIconsCollection - 1]
        )?.map(([key, value]) => (
          <div className="rms-items-wrap" key={key}>
            <div
              className="rms-item"
              style={{
                backgroundImage: `url(${returnMenuItemsBoxBG()})`,

                color: menuIconsColor,

                backgroundColor:
                  key == "live"
                    ? returnColor(menuItemsActiveColor, 10)
                    : "transparent",

                boxShadow:
                  key == "live"
                    ? `0.49px 0.49px 20.97px 0px ${
                        menuItemsActiveColor || "#27282a inset"
                      }`
                    : `0px 6.99px 6.99px 0px #27282a inset`,

                borderRadius: returnMenuItemsBoxForm(),
              }}
            >
              {key == "live" && menuItemsActiveColor && (
                <img
                  src={`data:image/svg+xml,${encodeURIComponent(
                    svgBackground
                  )}`}
                  className="light-effect"
                />
              )}
              {value}
            </div>

            <p
              className="rms-item-title"
              style={{
                color: menuIconsColor,
              }}
            >
              {key == "live" ? "LIVE TV" : key}
            </p>
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );
}
