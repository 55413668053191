import React, { useContext } from "react";
import { BrandingContext } from "../BrandingContext";
import { menuClassicIconsCollection } from "../../../config/data";

import Logo from "./Logo";
import ReloadApp from "./ReloadApp";

import "../styles/menu-classic.scss";

export default function MenuClassic() {
  const {
    backgroundImage,
    logo,
    logoSize,
    menuItemsBoxForm,
    menuItemsActiveColor,
    menuIconsColor,
    menuItemsColor,
    menuIconsCollection,
  } = useContext(BrandingContext);

  const returnMenuItemsBoxForm = () => {
    switch (menuItemsBoxForm) {
      case "round":
        return "50%";
      case "quadrate":
        return "0px";
      case "halfcircle":
        return "15px";
      default:
        return "50%";
    }
  };

  return (
    <div
      className="bg display-width"
      style={{
        backgroundImage: `url(${backgroundImage?.url || backgroundImage})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundRepeat: " no-repeat",
        position: "relative",
        backgroundColor: "#000",
      }}
    >
      <div className="tv-logo-container">
        <Logo logo={logo} logoSize={logoSize} />
      </div>

      <div className="tv-icons-container">
        {menuClassicIconsCollection[menuIconsCollection - 1] ? (
          Object?.entries(
            menuClassicIconsCollection[menuIconsCollection - 1]
          )?.map(([key, value]) => (
            <div
              className="tv-icon-item tv-icon-custom"
              style={{
                border: `2px solid ${
                  key == "live"
                    ? menuItemsActiveColor || "transparent"
                    : menuItemsColor
                }`,
                borderRadius: returnMenuItemsBoxForm(),
                color: `${menuIconsColor}`,
                backgroundColor: `${
                  key == "live"
                    ? "rgba(23, 23, 23, 0.5)"
                    : "rgba(29, 29, 29, 0.5)"
                }`,
                transform: `${key == "live" ? "scale(1.1)" : "none"}`,
              }}
              key={key}
            >
              {value}
              <span
                style={{ color: menuIconsColor, textTransform: "capitalize" }}
              >
                {" "}
                {key == "live" ? "Live TV" : key}
              </span>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>

      <div className="classic-wrap-reload">
        <ReloadApp />
      </div>

      <div className="tv-footer-container">
        <p>
          <span> Device Key: 3GK689</span>
        </p>
        <p>App version: 1.1.5</p>
      </div>
    </div>
  );
}
